<template>
  <div class="h-full">
    <div class="relative overflow-hidden mb-4">
      <video
        class="w-full mb-4 lg:h-800 border-none video-js h-300"
        preload="auto"
        controls
      >
        <source src="https://imgs.iclover.net/clover/video2.mp4" type="video/mp4" />
      </video>
    </div>

    <div class="max-w-screen-xl m-auto">
      
    <div class="text-center">
      <h2 class="text-2xl mb-1 md:text-4xl md:mb-4 font-normal not-italic">發送私人訊息</h2>
      <div class="inline-block md:text-3xl text-xl">
        <h3 class="mb-4">Facebook、Instagram、Threads、Telegram<br />四大平台連接</h3>
        <div class="h-1 bg-red-500 rounded-full mb-8 mx-auto" />
      </div>
    </div>

    <div class="text-center mb-12">
      <div v-if="!$auth.loggedIn">
        <FacebookLogin class="mb-4 mx-auto" />
      </div>
      <nuxt-link to="/home" class="btn max-w-xs m-auto" v-if="$auth.loggedIn">
        {{ $t('index.console') }}</nuxt-link
      >
    </div>

    <div class="flex mb-12 flex-row flex-wrap items-start justify-center px-2 md:px-0">
      <div
        class="w-1/2 md:w-1/3 text-center mb-8"
        v-for="feature in features"
        :key="feature.title"
      >
        <div class="m-auto max-w-xs tracking-wide">
          <icon
            :icon="feature.icon"
            v-if="feature.icon"
            class="w-10 h-10 m-auto mb-2 md:mb-4"
          />
          <h4 class="font-bold md:mb-2 text-base md:text-xl">
            {{ feature.title }}
          </h4>
          <p class="text-secondary text-xs md:text-base">
            {{ feature.content }}
          </p>
        </div>
      </div>
    </div>
    </div>
    <div class="h-40"></div>
  </div>
</template>

<script>
import Icon from "@/components/icons/Icon";
import tg from "@/components/icons/tg";
import FacebookLogin from '@/components/FB/Login'

export default {
  layout: "page",

  components: {
    Icon,
    tg,
    FacebookLogin
  },
  head() {
    return {
      meta: [
        { property: "og:title", content: process.env.APP_NAME },
        { property: "og:url", content: process.env.APP_URL },
        { property: "og:image", content: `${process.env.APP_URL}/unkoown.png` },
      ],
    };
  },
  async asyncData({ store }) {
    // await store.dispatch("app/fetchCustomers");
    // await $axios.get("/customers").then((resp) => {
    //   dispatch("app/setCustomers", resp.data.data);
    // });
  },
  data() {
    return {
      features: [
        {
          icon: "icon1",
          title: "完全匿名投稿",
          content: "安全 HTTPS / SSL 技術加密",
        },
        {
          icon: "icon2",
          title: "一鍵發佈 4 個平台",
          content: "支援Facebook、Instagram、Threads、Telegram平台一鍵發佈",
        },
        {
          icon: "icon3",
          title: "人手審批投稿",
          content: "過濾垃圾訊息",
        },
        {
          icon: "tg",
          title: "專屬後台 Telegram APP 管理",
          content: "新投稿即時通知",
        },
        {
          icon: "icon4",
          title: "內容全自動轉成圖片",
          content: "全面支援IG貼文提升觸及率",
        },
        {
          icon: "icon5",
          title: "投稿上傳圖片",
          content: "佔最大版面的FB、IG的圖片尺寸",
        },
        {
          icon: "icon6",
          title: "驗證碼反垃圾訊息技術",
          content: "避免大量廣告及機器人訊息洗",
        },
      ],
    };
  },
};
</script>
