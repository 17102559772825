<template>
  <button
    class="bg-[#1a77f2] text-white rounded font-bold flex h-10 items-center px-4 mx-auto"
    aria-label="使用 Facebook 登錄  "
    @click="login"
  >
    <svg viewBox="0 0 213 213" class="w-6 h-6 mr-4" preserveAspectRatio="xMinYMin">
      <path
        d="M90 212v-75H63v-31h27V81q0-40 40-40 15 0 24 2v26h-14q-16 0-16 16v21h30l-5 31h-27v75a106 106 0 1 0-32 0"
        class="f_logo_circle"
        fill="#fff"
      />
      <path
        d="M90 212v-75H63v-31h27V81q0-40 40-40 15 0 24 2v26h-14q-16 0-16 16v21h30l-5 31h-27v75a106 106 1 0 1-32 0"
        class="f_logo_f"
        fill="#1a77f2"
      /></svg
    ><span>使用 Facebook 登錄</span>
  </button>
</template>

<script>
export default {
  props: {
    img: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default() {
        return {
          scope: [
            
            "instagram_basic",
            "pages_show_list",
            // "instagram_content_publish",
            // "business_management",
            // "pages_manage_posts",
            // "pages_read_engagement",
          ].join(","),
        };
      },
    },
    callback: Function,
  },
  data() {
    return {
      binding: false,
    };
  },
  methods: {
    async login() {
      try {
        if (this.inapp) {
          const result = await window.flutter_inappwebview.callHandler(
            "fb.login",
            this.rerequest
          );

          await this.handleFbLoginFromApp(result);
          return;
        }

        await FB.login((response) => {
          if (this.callback) {
            return this.callback(response);
            return;
          }
          return this.handleResponse(response);
        }, this.options);
      } catch (e) {
        console.error(e);
      }
    },

    async handleFbLoginFromApp(result) {
      if (this.$auth.loggedIn) {
        await this.$axios.post("/me", result);
        if (this.isInPermissions()) return this.redirect();
        this.$emit("update");
        return;
      }

      await this.$auth.login({
        data: result,
      });
      this.saveAppToken();
      return this.redirect();
    },

    async handleResponse(response) {
      if (response.status != "connected" || this.binding) return;
      try {
        this.binding = true;
        this.$loader.load();

        const access_token = response.authResponse.accessToken;
        if (!this.$auth.loggedIn) await this.auth(access_token);
        else await this.update(access_token);
        this.$loader.close();
      } catch (e) {
        console.error(e);
        this.$loader.error();
      } finally {
        this.binding = false;
      }
    },

    auth(access_token) {
      return this.fetchMe().then((response) => {
        return this.$auth.login({
          data: {
            ...response,
            access_token,
          },
        });
      });
    },

    async update(access_token) {
      return this.fetchMe().then((response) => {
        return this.$axios.post("/me", {
          ...response,
          access_token,
        });
      });
    },

    fetchMe() {
      return new Promise((resolve, reject) => {
        FB.api("/me", (response) => {
          if (response.error) {
            reject(response.error);
            return;
          }
          resolve(response);
        });
      });
    },

    redirect() {
      this.$router.push(this.localePath(this.getRedirectPath()));
    },

    isInPermissions() {
      return this.$route.name == "permissions";
    },
  },
};
</script>
